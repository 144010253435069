.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Helper classes 2 */

.flex {
  display: -webkit-box;
  display: flex;
}

.flex-1 {
  -webkit-box-flex: 1;
          flex: 1;
}
.items-center {
  -webkit-box-align: center;
  align-items: center;
}

.pb-16 {
  padding-bottom: 1.6rem;
}

.ml-8 {
  margin-left: 1.6rem;
}
.ml-16 {
  margin-left: 1.6rem;
}

div.listeAttente .rt-tr.notempty.-odd {
  background: rgb(255,255,0,0.5);
}
div.listeAttente .rt-tr.notempty.-even {
  background: rgb(255,255,0,0.25);
}
/* react table */
